import React, { useState } from "react";
import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H1 from "~/components/Typography/H1";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import { StudentCard, TeacherCard } from "~/components/content-card";

// URL of Prescription drug ad for reference
// href="https://www.fda.gov/Drugs/ResourcesForYou/Consumers/PrescriptionDrugAdvertising/ucm082284.htm"
const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);
  return (
    <LessonLayout
      current_step={6}
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Step 6</H1>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={false}
            setFullScreen={setStudentFullScreen}
          >
            <p>When companies advertise medicines, they need to follow certain rules set by the US Food and Drug Administration (FDA). The rules for prescription medications (medications that require a doctor's order) are stricter than those for medicines you can buy off the shelf. This is because prescription medications are more powerful and have a higher chance of hurting someone who does not take them correctly.</p>
            <p>Click on the ad link to learn more about the rules for advertising prescription drugs.</p>
            <Button
              action="secondary"
              color="purple"
              href="/prescription-drug-ad"
              icon="external"
              title="Prescription Drug Ad"
            >
              Prescription drug ad
            </Button>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={false}
            setFullScreen={setTeacherFullScreen}
          >
            <p>In this step, students learn that ads for prescription medications are regulated by the FDA and that companies must include a balance of benefits and risks in their advertisements for these medications. The goal of this exercise is not necessarily for students to memorize all the required elements of an advertisement for a medication but rather to learn what to look for in an ad and to recognize that different types of medications have different requirements.</p>
            <p><strong>Optional:</strong> If you wish, you can show the following FDA web page to students: "Incorrect Product Claim Ad.
"</p>
            <Button
              action="secondary"
              color="purple"
              href="https://www.fda.gov/drugs/prescription-drug-advertising/incorrect-product-claim-ad"
              icon="external"
              title="Incorrect product claim ad
"
            >
              Incorrect product claim ad

            </Button>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

